<section class="loginPage">
	<div class="loginCardWrap">
		<div class="login-m">
			<div class="logincard_left">
				<div class="login-logo">
					<img [src]="loginLogo" alt="Logo">
				</div>
				<div class="center_content">
					<div class="img-m">
						<img [src]="loginCenter"  alt="">
					</div>
					<div class="login-text">
						<p *ngIf="hours < 12">Hello! Good Morning</p>
						<p *ngIf="hours > 12 && hours < 18">Hello! Good Afternoon</p>
						<p *ngIf="hours >= 18">Hello! Good Evening</p>
						<span>Sign into your account.</span>
					</div>
				</div>
			</div>
			<div class="logincard_right">
				<span class="top-img">
					<img src="assets/images/login-circle.svg" alt="Logo">
				</span>
				<div class="loginCard">
					<div class="cardContent">
						<h4 class="cardTitle">Welcome</h4>
						<p class="enter_txt">Enter your email and password.</p>
						<div class="alert alert-danger" [ngClass]="{'af-none': !showErrorAlert}">
							<strong>{{ ErrorMessage }}</strong>
						</div>
						<form class="form-signin">
							<div class="form-label-group inputSingle icon">
								<input type="email" (keyup.enter)="login()" id="inputEmail" class="form-control" placeholder="" [(ngModel)]="admin.email" [ngModelOptions]="{standalone: true}" placeholder="Email">
								<label for="inputEmail">Email</label>
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" clip-rule="evenodd" d="M4.636 5.04501H19.363C20.267 5.04501 21 5.77801 21 6.68201V17.318C21 18.222 20.267 18.954 19.364 18.954H4.636C3.733 18.955 3 18.222 3 17.318V6.68201C3 5.77801 3.733 5.04501 4.636 5.04501Z" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M3.1189 6.075L10.8129 11.578C11.5079 12.075 12.4419 12.076 13.1379 11.58L20.8759 6.061" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</div>
							<div class="form-label-group inputSingle icon">
								<input type="password" (keyup.enter)="login()" id="inputPassword" class="form-control" placeholder="" [(ngModel)]="admin.password" [ngModelOptions]="{standalone: true}" placeholder="Password">
								<label for="inputPassword">Password</label>
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M8 10V7V7C8 4.791 9.791 3 12 3V3C14.209 3 16 4.791 16 7V7V10" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path d="M12 14V17" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									<path fill-rule="evenodd" clip-rule="evenodd" d="M17 21H7C5.895 21 5 20.105 5 19V12C5 10.895 5.895 10 7 10H17C18.105 10 19 10.895 19 12V19C19 20.105 18.105 21 17 21Z" stroke="#828282" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
								</svg>
							</div>
						</form>
						<button class="primaryBtn" id="loginBtn" (click)="login()" [disabled]="loading">
							<i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
							Login
						</button>
					</div>
				</div>
				<span class="bottom-img">
					<img src="assets/images/login_rocket.svg" alt="Logo">
				</span>
			</div>
		</div>
	</div>
</section>
