import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { AppSettings } from './app.setting';
import { BehaviorSubject, Observable, throwError, Subject } from 'rxjs';
import { catchError, retry, finalize, tap, map, takeUntil } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';


declare var config: any;

import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class HelperService {
    public corsHeaders: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    });;

    public searchParms : any;

    // Https Params
    protected ngUnsubscribe: Subject<void> = new Subject<void>();
    public httpLoading     = false;

    constructor(
      private http: HttpClient,
      private authenticationService: AuthenticationService,
    ) {
        // // Set Params OnLoad
        // this.searchParms = JSON.parse(localStorage.getItem('searchParms'));

        // this.corsHeaders = {};
        // this.corsHeaders = {
        //               headers: new HttpHeaders()
        //                 .set('Cache-Control',  'no-cache, no-store, must-revalidate, post- check=0, pre-check=0')
        //                 .set('Pragma',   'no-cache')
        //                 .set('Expires',  '0')
        //                 // .set('Authorization',  `Bearer ${user.access_token}`)
        //             }

        let subscription = this.authenticationService.currentUserS$.subscribe(user => {
          if (user) {
              this.corsHeaders = {
                    headers: new HttpHeaders()
                      .set('Content-Type',  'application/json')
                      .set('Accept',  'application/json')
                      .set('Authorization',  `Bearer ${user.access_token}`)
                  }
          } else {
              this.corsHeaders = {};
              this.corsHeaders = new HttpHeaders({
                                        'Content-Type': 'application/json',
                                        'Accept': 'application/json',
                                      });;
          }
        });
    }

    getCurrentDateTimeString(dateString) {
      var date = new Date(dateString);

      var yr   = date.getFullYear();
      var mon  = date.getMonth() + 1;
      var mo   = mon < 10 ? '0' + mon : mon;
      var dayI = date.getDate();
      var day  = dayI < 10 ? '0' + dayI : dayI;

      var hours = date.getHours();
      var hr = hours < 10 ? '0' + hours : hours;

      var minutes = date.getMinutes();
      var min = (minutes < 10) ? '0' + minutes : minutes;

      var seconds = date.getSeconds();
      var sec = (seconds < 10) ? '0' + seconds : seconds;

      var newDateString = yr + '-' + mo  + '-' + day;
      var newTimeString = hr + ':' + min + ':' + sec;

      // 2021-07-01T19:00:00.000Z
      // 2021-07-01T00:00:00
      // 2021-07-01T00:00:00.000Z
      return newDateString + 'T' + newTimeString + '.000Z';
    }

    caonvertDateToString(timestamp, start = true){
      var x = new Date(timestamp);
      var dd = x.getDate() > 9 ? x.getDate() : '0' + x.getDate();
      var mm = x.getMonth() + 1 > 9 ? x.getMonth() + 1 : '0' + (x.getMonth() + 1);
      var yy = x.getFullYear();
      if(start)
        return yy + "-" + mm + "-" + dd + "T00:00:00.000Z";
      else
        return yy + "-" + mm + "-" + dd + "T23:59:59.000Z";
    }

    convertDateToString(timestamp, start = true){
      var x = new Date(timestamp);
      var dd = x.getDate() > 9 ? x.getDate() : '0' + x.getDate();
      var mm = x.getMonth() + 1 > 9 ? x.getMonth() + 1 : '0' + (x.getMonth() + 1);
      var yy = x.getFullYear();
      if(start)
        return yy + "-" + mm + "-" + dd;
      else
        return yy + "-" + mm + "-" + dd;
    }

    decrypted(encrypted) {
       let key = 'bNbbwKofgalw20nGI7njEQ==';
       var encrypted_json = JSON.parse(atob(encrypted));

       return CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(key)
    , {
         iv: CryptoJS.enc.Base64.parse(encrypted_json.iv)
       }).toString(CryptoJS.enc.Utf8);
   };

    //////////////////////////////////////////
    /********* HTTP Requests Fns ***********/
    ////////////////////////////////////////
    httpGetRequests(url): Promise<any> {
        // Set loader true
        this.httpLoading = true;

        return this.http.get(url, this.corsHeaders)
            .pipe( takeUntil(this.ngUnsubscribe) )
            .toPromise()
            .then( resp => {
                // Set loader false
                this.httpLoading = false;

                return resp;
                // console.log("resp: ",resp);
            })
            .catch(error => {
                // Set loader false
                this.httpLoading = false;
                console.log("helperFunc error: ",error);

                // Show Error Msg
                if(typeof error.error != "undefined") {
                    if(error.error.message == "Unauthenticated.") {
                      this.authenticationService.logout();
                    }

                    throw error;
                } else {
                    throw "Something went wrong. Please try again.";
                }
            });
      }

      httpPostRequests(url, data): Promise<any> {
          // Set loader true
          this.httpLoading = true;

          return this.http.post(url, data, this.corsHeaders)
              .pipe( takeUntil(this.ngUnsubscribe) )
              .toPromise()
              .then( resp => {
                  // Set loader false
                  this.httpLoading = false;
                  return resp;
              })
              .catch(error => {
                  // Set loader false
                  this.httpLoading = false;
                  console.log("error: ",error);

                  // Show Error Msg
                  if(typeof error.error != "undefined") {
                      if(error.error.message == "Unauthenticated.") {
                        this.authenticationService.logout();
                      }

                      throw error;
                  } else {
                      throw "Something went wrong. Please try again.";
                  }
              });
        }

      httpPostRequestsDecryp(url, data): Promise<any> {
            // Set loader true
            this.httpLoading = true;

            return this.http.post(url, data, this.corsHeaders)
                .pipe( takeUntil(this.ngUnsubscribe) )
                .toPromise()
                .then( resp => {
                    // Set loader false
                    resp = this.decrypted(resp["data"]);
                    // console.log("resp ******", resp);

                    this.httpLoading = false;
                    return resp;
                })
                .catch(error => {
                    // Set loader false
                    this.httpLoading = false;
                    console.log("error: ",error);

                    // Show Error Msg
                    if(typeof error.error != "undefined") {
                        if(error.error.message == "Unauthenticated.") {
                          this.authenticationService.logout();
                        }

                        throw error;
                    } else {
                        throw "Something went wrong. Please try again.";
                    }
                });
          }

      httpDeleteRequests(url): Promise<any> {
          // Set loader true
          this.httpLoading = true;

          return this.http.delete(url, this.corsHeaders)
              .pipe( takeUntil(this.ngUnsubscribe) )
              .toPromise()
              .then( resp => {
                  // Set loader false
                  this.httpLoading = false;

                  return resp;
              })
              .catch(error => {
                  // Set loader false
                  this.httpLoading = false;

                  console.log("error: ",error);

                  // Show Error Msg
                  if(typeof error.error != "undefined") {
                      if(error.error.message == "Unauthenticated.") {
                        this.authenticationService.logout();
                      }

                      throw error;
                  } else {
                      throw "Something went wrong. Please try again.";
                  }
              });
        }
}
