import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, Route, ActivatedRoute } from "@angular/router";
import { AppSettings, AuthenticationService, UserService, HelperService } from '../../services/_services';

declare var google, window;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginLogo: string = '';
  loginCenter: string = '';

  public admin          : any = { email: '', password: '' }
  public UserLoginKey   : any;
  public loading        : any = false;
  public showErrorAlert : any = false;
  public ErrorMessage   : any = '';

  public animationDone  : any = false;
  public navDateTime    : any;
  public hours          : any;
  public myInterval     : any;


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private userService           : UserService,
    private authenticationService : AuthenticationService,
    private activatedRoute        : ActivatedRoute,
    private router                : Router,
    private helperService         : HelperService,
  ) {
      if (this.authenticationService.get_currentUserValue()) { // Redirect to dashboard if already logged in
          this.router.navigate(['/']);
      } else {
          // Get User login key
          this.UserLoginKey = this.authenticationService.get_UserLoginKey();
      }
  }

  ngOnInit() {
    const baseUrl = this.document.location.origin;

    // Set the logo URL based on the base URL
    if (baseUrl.includes('writing4money')) {
      this.loginLogo = 'assets/images/w4m-logo.svg'; // Default logo
      this.loginCenter = 'assets/images/w4m-center.svg'; // Default logo
      
    } else {
      
      this.loginLogo = 'assets/images/skyscraper-login-logo.svg'; // Replace with your logo for route1
      this.loginCenter = 'assets/images/login-center.svg'; // Replace with your logo for route1
    }
    this.navDateTime = this.convertTZ(new Date(), 'America/Los_Angeles');
    this.hours = this.navDateTime.getHours();
    this.myInterval = setInterval(() => {
      this.navDateTime = this.convertTZ(new Date(), 'America/Los_Angeles');
    }, 20000);
  }

  convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
  }

  ngAfterViewInit() {

  }

  closeAlert() {
    this.showErrorAlert = false;
  }

  login() {
    // Close previous errors
    this.closeAlert();

    // Show Loader
    this.loading = true;

    this.getIpAddress().then(ip => {
        let url  = AppSettings.API_ENDPOINT + 'login';

        if(this.UserLoginKey){
          // console.log("this.UserLoginKey", this.UserLoginKey);

        }
        else {
          this.UserLoginKey = this.randomString(32, '#aA');
        }

        let data = {email: this.admin.email, password: this.admin.password, ip_address: ip, browser: this.getBrowserInfo(), login_key: this.UserLoginKey}

        this.helperService.httpPostRequests(url, data)
           .then(user => {
                // Hide Loader
                this.loading = false;
                // console.log("user", user);

                if(!user.error) {
                    user.Portal_SuperAdmin = user.Portal_Sales = user.Portal_Support = user.Portal_QA = user.Portal_Proofreader = user.Portal_Writer = user.is_admin = user.is_agent = user.is_sales = user.is_writermanager = user.is_projectmanager = user.is_qualityassurance = user.is_it = user.is_hr = user.is_proofreader = user.is_proofreader_manager = user.is_writer = false;
                    if(user.user.id == 712) {
                       user.Portal_SuperAdmin = true;
                    } else if (user.user.region == 'admin') {
                       user.is_admin = true;
                    }
                    else if (user.user.is_newType)
                      user.is_newType = true;
                    else if (user.user.region == 'agent') {
                       user.Portal_Support = true;
                       user.is_agent       = true;
                    } else if (user.user.region == 'sales') {
                       user.Portal_Sales = true;
                       user.is_sales     = true;
                    } else if (user.user.region == 'writer-manager' || user.user.region == 'in-writer-manager' || user.user.region == 'wm-freelance') {
                       user.is_writermanager = true;
                       user.Portal_Writer    = true;
                    } else if (user.user.region == 'project-manager') {
                       user.is_projectmanager = true;
                       user.Portal_SuperAdmin = true;
                    } else if (user.user.region == 'quality-assurance') {
                       user.Portal_QA           = true;
                       user.is_qualityassurance = true;
                    } else if (user.user.region == 'it') {
                       user.is_it = true;
                    } else if (user.user.region == 'hr') {
                       user.is_hr = true;
                    } else if (user.user.region == 'proofreader') {
                       user.Portal_Proofreader = true;
                       user.is_proofreader     = true;
                    } else if (user.user.region == 'proofreader-manager') {
                       user.Portal_Proofreader     = true;
                       user.is_proofreader_manager = true;
                    } else if (user.user.region == 'Local' || user.user.region == 'NFW' || user.user.region == 'Foreigner' || user.user.region == 'inhouse') {
                       user.Portal_Writer = true;
                       user.is_writer     = true;
                    }

                    // console.log('user -> ' ,user);

                    this.authenticationService.setCurrentUserValue(user);
                    this.authenticationService.setUserLoginKey(user['login_key']);
                    this.router.navigate(['/']);
                } else {
                    // Show Error Measage
                    // console.log("this.ErrorMessage", this.ErrorMessage);

                    this.showErrorAlert = true;
                    this.ErrorMessage   = user.error;
                }
            },
            error => {
                // console.log("error: ",error);
                if(typeof error.message != "undefined") {
                  this.showErrorAlert = true;
                  this.ErrorMessage =  error.error.message;
                  // console.log("error: ",error.error.message);
                } else {
                    alert(JSON.stringify(error));
                }

                // Hide Loader
                this.loading = false;
            });
    });
  }

  // login2(){
  //   if (this.admin.email == this.adminSky.email && this.admin.password == this.adminSky.password) {
  //       this.authenticationService.setCurrentUserValue(this.adminSky);
  //       this.router.navigate(['/']);
  //       this.loading = false;
  //       this.showErrorAlert = false;
  //   }
  //   else{
  //       this.showErrorAlert = true;
  //       this.loading = false;
  //   }
  // }

  getIpAddress(): Promise<any> {
    let url  = AppSettings.API_ENDPOINT + 'get-client-ip';

    return this.helperService.httpGetRequests(url).then(resp => {
        return resp;
    }).catch(error => {
        console.log("error: ", error);
    });
  }

  getAdress(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resp => {
          let pos = {lng: resp.coords.longitude, lat: resp.coords.latitude};
          var geocoder = new google.maps.Geocoder;
          geocoder.geocode({'location': pos}, function(results, status) {
            if (status === 'OK') {
              if (results[0]) {
                  resolve(results[0].formatted_address);
              }
            }
          });
        },
        err => {
          reject(err);
        });
    });
  }

  randomString(length, chars) {
    var mask = '';
    if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
    if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    if (chars.indexOf('#') > -1) mask += '0123456789';
    if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
    var result = '';
    for (var i = length; i > 0; --i) result += mask[Math.round(Math.random() * (mask.length - 1))];
    return result;
  }

  geocodeLatLng(pos) {
    var geocoder = new google.maps.Geocoder;
    geocoder.geocode({'location': pos}, function(results, status) {
      if (status === 'OK') {
        if (results[0]) {
            console.log("results[0]: ",results[0]);
        }
      }
    });
  }

  getBrowserInfo() {
    var nVer = navigator.appVersion;
    var nAgt = navigator.userAgent;
    var browserName  = navigator.appName;
    var fullVersion  = ''+parseFloat(navigator.appVersion);
    var majorVersion = parseInt(navigator.appVersion,10);
    var nameOffset,verOffset,ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
     browserName = "Opera";
     fullVersion = nAgt.substring(verOffset+6);
     if ((verOffset=nAgt.indexOf("Version"))!=-1)
       fullVersion = nAgt.substring(verOffset+8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
     browserName = "Microsoft Internet Explorer";
     fullVersion = nAgt.substring(verOffset+5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
     browserName = "Chrome";
     fullVersion = nAgt.substring(verOffset+7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
     browserName = "Safari";
     fullVersion = nAgt.substring(verOffset+7);
     if ((verOffset=nAgt.indexOf("Version"))!=-1)
       fullVersion = nAgt.substring(verOffset+8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
     browserName = "Firefox";
     fullVersion = nAgt.substring(verOffset+8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) <
              (verOffset=nAgt.lastIndexOf('/')) )
    {
     browserName = nAgt.substring(nameOffset,verOffset);
     fullVersion = nAgt.substring(verOffset+1);
     if (browserName.toLowerCase()==browserName.toUpperCase()) {
      browserName = navigator.appName;
     }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix=fullVersion.indexOf(";"))!=-1)
       fullVersion=fullVersion.substring(0,ix);
    if ((ix=fullVersion.indexOf(" "))!=-1)
       fullVersion=fullVersion.substring(0,ix);

    majorVersion = parseInt(''+fullVersion,10);
    if (isNaN(majorVersion)) {
     fullVersion  = ''+parseFloat(navigator.appVersion);
     majorVersion = parseInt(navigator.appVersion,10);
    }

    // console.log("browserName: ",browserName);
    // console.log("fullVersion: ",fullVersion);
    // console.log("majorVersion: ",majorVersion);
    // console.log("navigator.appName: ",navigator.appName);
    // console.log("navigator.userAgent: ",navigator.userAgent);
    return browserName;
  }

}
